import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'

const services = [
  // GET Return pickups
  { name: 'getPickups', url: `${environment.puconApiUrl}/pickups?type=return`, method: 'get' },
  // GET Carriers
  { name: 'getCarriers', url: `${environment.puconApiUrl}/carriers`, method: 'get' },
  // POST Download pickups
  { name: 'downloadPickups', url: `${environment.puconApiUrl}/pickups-download`, method: 'post'},
  // GET Carriers distribution
  { name: 'getCarriersDistribution', url: `${environment.puconApiUrl}/shippers/{shipper_id}/carriers?has_reverse_logistic=true`, method: 'get' },
  // GET NonWorkingDays
  { name: 'getNonWorkingDays', url: `${environment.puconApiUrl}/carriers/{carrier_code}/non-working-days`, method: 'get' },
  // GET Places
  { name: 'getPlaces', url: `${environment.apiPlatform}/v1/countries/{country_code}/grouped-places`, method: 'get'},
  // GET Ots
  { name: 'getOts', url: `${environment.apiPlatform}/v1/return-deliveries-for-pickups`, method: 'get'},
  // GET Evaluation statuses
  { name: 'getEvaluationStatuses', url: `${environment.puconApiUrl}/pickup-evaluation-statuses?not_visible=false&new_mode=true`, method: 'get'},
  // GET'S Companies (Shippers / Organizations)
  { name: 'getShippers', url: `${environment.puconApiUrl}/shippers?has_reverse_logistic=true`, method: 'get' },
  { name: 'getOrganizations', url: `${environment.dubaiApiUrl}/organizations`, method: 'get' },
  // Evaluate pickup
  { name: 'evaluatePickup', url: `${environment.puconApiUrl}/shippers/{shipper_id}/pickup/{identifier}/evaluations`, method: 'post'},
  // Delete pickup
  { name: 'deletePickup', url: `${environment.puconApiUrl}/shippers/{shipper_id}/pickups/{identifier}`, method: 'delete'},
  // POST Create retun pickup
  { name: 'createReturnPickup', url: `${environment.puconApiUrl}/shippers/{shipper_id}/return-pickups`, method: 'post'}
]

export default class ReturnsService {
  constructor(context) {
    this.context = context
  }
  async callService (name, queryParams = {}, params = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params)
  }
  
  async callMultipleServices (callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }
}