<template>
  <div>
    <b-card>
      <!-- Boton crear retiro -->
      <div class="col-md-12 d-flex justify-content-end">
        <b-button v-if="!loading.first" @click="openModalCreatePickup" class="col-md-2" variant="warning">
          <feather-icon icon="FileTextIcon"/>
          {{$t('Crear retiro')}} 
        </b-button>
      </div>
      <!-- Filtros -->
      <div class="col-md-12 mb-1">
        <b-skeleton v-if="loading.first" type="input" class="mt-2 mb-2 spacing-label-field" height="40px"/>
        <form-render
          v-if="!loading.first"
          :fields="fields"
          :form.sync="formFilter"
          :buttonSend="buttonSend"
          @send="filterForm"
          :containerButtonsClass="containerButtonsClass">
            <template #buttons>
              <b-dropdown class="mx-1" v-b-tooltip.hover :title="$t('Más opciones')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="light"> 
                <template #button-content><feather-icon icon="SettingsIcon"/></template>
                <b-dropdown-item @click="cleanFilter">{{$t('Limpiar filtros')}}</b-dropdown-item>
                <b-dropdown-item @click="openAdvancedFiltersModal">{{$t('Búsqueda avanzada')}}</b-dropdown-item>
              </b-dropdown>
            </template>
        </form-render>
      </div>

      <div v-if="!loading.first" class="my-2 col-md-12 col-lg-12 d-flex justify-content-end">
        <!-- Exportar retiros normales -->
        <b-button @click="downloadExcel()" :disabled="loading.excel" variant="success">
          <feather-icon v-if="!loading.excel" icon="DownloadIcon"/>
          <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/> 
          {{$t('Exportar retiros')}}
        </b-button>
      </div>

      <!-- Tabla -->
      <div class="col-md-12">
        <div class="table-render-skeleton" v-if="loading.first">
          <b-skeleton-table
          :rows="pagination.limit || 10"
          :columns="schema.length || 10"
          :table-props="{}"
          />
        </div>
        <table-render v-if="!loading.first" id="return-pickups" :rows="rows" :schema="schema" :loading="pagination.loading">
          <!-- Compañia -->
          <template #company="scope">
            <span>{{ scope.rowdata.company.name }} - <strong>{{ scope.rowdata.company.id }}</strong></span>
          </template>
          <!-- Dirección -->
          <template #address="scope">
            <span v-if="scope.rowdata.address">{{ scope.rowdata.address.full_address }}, <strong>{{ scope.rowdata.address.place }}</strong></span>
          </template>
          <!-- Contacto -->
          <template #contact="scope">
            <b-button v-if="scope.rowdata.contact" v-b-tooltip.hover.html="$generateTooltipPerson(scope.rowdata.contact)" variant="link " >{{scope.rowdata.contact.name}}</b-button>
          </template>
          <!-- Estado -->
          <template #status="scope">
            <div class="d-flex">
              <span class="table_dot--state" :class="getStatusClass(scope.rowdata.status.code)"></span>
              <span class="ml-1">{{ scope.rowdata.status.name }}</span>
            </div>
          </template>

          <!-- Acciones -->
          <template #pickup_actions="scope">
            <div class="text-center">
              <!-- Ver detalle de retiro -->
              <a class="text-primary" @click="showPickupDetails(scope.rowdata)" v-b-tooltip.hover :title="$t('Ver detalles')"><feather-icon icon="EyeIcon"/></a>
              <!-- Eliminar retiro -->
              <b-button
                class="text-danger"
                variant="link"
                :disabled="loading.delete"
                v-if="mySession.role === ROLES.admin && !scope.rowdata.carrier.is_integrated"
                @click="confirmDeletePickup(scope.rowdata)"
                v-b-tooltip.hover
                :title="$t('Eliminar retiro')">
                  <feather-icon icon="TrashIcon"/>
              </b-button>
              <!-- <a class="mr-1 text-danger" :disabled="loading.delete" v-if="mySession.role === ROLES.admin" @click="confirmDeletePickup(scope.rowdata)" v-b-tooltip.hover :title="$t('Eliminar retiro')"><feather-icon icon="TrashIcon"/></a> -->

              <!-- Evaluar retiro -->
              <div class="mt-1">
                <b-button v-if="scope.rowdata.need_evaluation && scope.rowdata.status.code != 'canceled'" variant="light" size="sm" class="mr-1" @click="showReviewPickup(scope.rowdata)">
                  <span v-b-tooltip.hover placement="bottom" title="Evaluar retiro">
                    Evaluar
                  </span>
                </b-button>
                <b-button v-if="!scope.rowdata.need_evaluation && !scope.rowdata.evaluation && scope.rowdata.status.code != 'canceled'" variant="secondary" size="sm" class="mr-1" @click="showReviewPickup(scope.rowdata)">
                  <span v-b-tooltip.hover :title="`Evaluable a partir de ${scope.rowdata.format_pickup_date} a las 20:00 Hrs`">
                    Evaluar
                  </span>
                </b-button>
              </div>
            </div>
          </template>
        </table-render>
        <!-- Paginación retiros normales -->
        <pagination v-if="!loading.first" :pagination="pagination" :noDigits="true" :showSize="true"/>
      </div>
    </b-card>

    <!-- Componentes -->
    <modal-return-pickup-filters :clearFilters="clearModalFilters" @updateAdvancedFilters="updateAdvancedFilters"></modal-return-pickup-filters>
    <modal-return-pickup-details :pickup="currentPickup.details"></modal-return-pickup-details>
    <modal-create-return-pickup @updateList="updateList"></modal-create-return-pickup>
    <modal-evaluate-return-pickup :pickup="currentPickup.evaluate" @updatePickupStatus="updatePickupStatus"></modal-evaluate-return-pickup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import ModalReturnPickupFilters from './ModalReturnPickupFilters.vue'
import modalReturnPickupDetails from './ModalReturnPickupDetails.vue'
import ModalCreateReturnPickup from './ModalCreateReturnPickup.vue'
import ModalEvaluateReturnPickup from './ModalEvaluateReturnPickup.vue'
import TabReturnPickupsService from './returnPickups.service'
export default {
  components: { ModalReturnPickupFilters, modalReturnPickupDetails, ModalCreateReturnPickup, ModalEvaluateReturnPickup },
  data() {
    return {
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin'
      },
      loading: {
        first: true,
        excel: false,
        delete: false
      },
      currentPickup: {
        details: {},
        evaluate: {}
      },
      fields: [],
      formFilter: {},
      clearModalFilters: 0,
      filters: {},
      advancedFilters: {},
      containerButtonsClass: 'col-sm-12 col-md-8 container-button mt-2',
      buttonSend: { icon: 'SearchIcon', color: 'warning', title: 'Filtrar' },
      initialDateRange: {
        start: moment().add(-65, 'days').toDate(),
        end: moment().add(6, 'days').toDate()
      },
      rows: [],
      schema: [],
      pagination: { page: 1, limit: 20, total: 20, loading: false },
      tabReturnPickupsService: new TabReturnPickupsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    'pagination.page' () {
      this.getReturnPickups()
    },
    'pagination.limit' () {
      this.getReturnPickups()
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.loading.first = false
      this.schema = [
        { label: 'Seller - ID', key: 'company', useSlot: true },
        { label: 'Fecha de retiro', sortable: true, key: 'pickup_date' },
        { label: 'Rango horario', key: 'range_time' },
        { label: 'Envíos', key: 'qty' },
        { label: 'Dirección', key: 'address', useSlot: true },
        { label: 'Courier', key: 'carrier.name' },
        { label: 'N° de retiro', key: 'identifier' },
        { label: 'Contacto', key: 'contact', useSlot: true },
        // { label: 'Tipo de retiro', key: 'type.name' },
        { label: 'Estado courier', key: 'status_carrier.code' },
        { label: 'Estado', key: 'status', useSlot: true },
        { label: 'Acciones', key: 'pickup_actions', useSlot: true }
      ]
      this.fields = [
        { 
          fieldType: 'FieldDatepicker', 
          label: 'Fecha de búsqueda', 
          name: 'date', 
          range: true,
          containerClass: 'col-sm-12 container-info col-md-2'
        },
        {
          fieldType: 'FieldInput', 
          name: 'identifier',
          type: 'text',
          label: 'N° de retiro',
          clearable: true,
          containerClass: 'col-sm-12 container-info col-md-2'
        }
      ]
      this.formFilter = {
        date: this.initialDateRange
      }

      this.filterForm(this.formFilter)
    },
    filterForm (form) {
      form.clear = true
      this.filterList({...this.advancedFilters, ...form})
    },
    cleanFilter () {
      this.formFilter = {
        date: this.initialDateRange,
        clear: true
      }
      this.clearModalFilters++
      this.filterList(this.formFilter)
    },
    filterList (data) {
      this.filters = {}
      if (data.clear) this.filters['clear_filters'] = true
      if (data.date) {
        this.filters['pickup_date_from'] = this.$options.filters.moment(data.date.start, 'YYYY-MM-DD')
        this.filters['pickup_date_to'] = this.$options.filters.moment(data.date.end, 'YYYY-MM-DD')
      }
      if (data.identifier) this.filters['carrier_pickup_number'] = Number(data.identifier)
      if (data.organization) this.filters['marketplace'] = data.organization.id
      if (data.shipper) this.filters['company'] = data.shipper.id
      if (data.carrier) this.filters['carrier'] = data.carrier.code

      // En caso de NO ser admin seteara el mkp o seller dependiendo del role del user
      if (this.mySession.role !== this.ROLES.admin) {
        this.filters = this.setFiltersByRole(this.filters)
      }

      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.getReturnPickups()
    },
    /** 
     * Función que setea filtros según el rol del usuario
    */
    setFiltersByRole (filters) {
      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) filters = { ...filters, company: this.mySession.shipper.id }
      if (this.mySession.role === this.ROLES.marketplace) filters = { ...filters, marketplace: this.mySession.organization.id }
      return filters
    },
    getReturnPickups() {
      // Loading de la tabla y limpiar parametro clear filters
      this.pagination.loading = true
      delete this.filters.clear_filters
      const payload = {
        old: true,
        limit: this.pagination.limit,
        page: this.pagination.page,
        ...this.filters
      }
      this.tabReturnPickupsService.callService('getPickups', payload)
        .then(resp => {
          this.setPickupRows(resp.data)
        })
        .catch(err => {
          console.error(err)
        })        
        .finally(() => {
          this.pagination.loading = false
        })
    },
    setPickupRows (pickups) {
      this.rows = pickups.map(pickup =>  {
        pickup.format_pickup_date = this.$options.filters.moment(pickup.pickup_date, 'DD-MM-YYYY')
        return pickup
      })
    },
    getStatusClass(statusCode) {
      const statusClassMap = {
        scheduled: 'table_dot--status-scheduled',
        done: 'table_dot--status-done',
        failed: 'table_dot--status-failed',
        canceled: 'table_dot--status-canceled'
      }
      return statusClassMap[statusCode] || 'table_dot--status-done'
    },
    downloadExcel () {
      this.loading.excel = true
      this.filters.type = 'return'
      this.tabReturnPickupsService.callService('downloadPickups', this.filters, null)
        .then(resp => {
          if (resp.data.code === 'OK') {
            window.open(resp.data.url, '_blank')
          } else {
            this.$alert(this.$t('Ocurrió un problema al exportar el archivo'))
          }
        })
        .catch(err => {
          console.error(err)
          this.$alert(this.$t('Ocurrió un problema al exportar el archivo'))
        })
        .finally(end => {
          this.loading.excel = false
        })
    },
    openModalCreatePickup() {
      this.$bvModal.show('modalCreateReturnPickup')
    },
    updateList (newPickup) {
      this.$bvModal.hide('modalCreateReturnPickup')
      this.rows.unshift(newPickup)
    },
    openAdvancedFiltersModal() {
      this.$bvModal.show('modalReturnPickupFilters')
    },
    showPickupDetails (pickup) {
      this.currentPickup.details = pickup
      this.$bvModal.show('modalReturnPickupDetails')
    },
    confirmDeletePickup (pickup) {
      if (!pickup.carrier.is_integrated) this.$confirm('¿Está seguro de eliminar el retiro?', () => this.deleteReturnPickup(pickup), 'Eliminar retiro de devolución', 'Eliminar', 'Cancelar')
      else this.$alert(this.$t('No es posible eliminar retiros de carriers integrados'))
    },
    deleteReturnPickup(pickup) {
      this.loading.delete = true
      const { first_name, last_name, email, role } = this.mySession
      const queryParams = {
        user: {
          first_name,
          last_name,
          email,
          role
        }
      }
      const params = {
        shipper_id: pickup.company.id,
        identifier: pickup.identifier
      }
      this.tabReturnPickupsService.callService('deletePickup', queryParams, params)
        .then(resp => {
          this.$success('Retiro eliminado correctamente')
          this.rows = this.rows.filter(row => row.identifier !== params.identifier)
        })
        .catch(err => {
          console.error(err)
          this.$alert(err)
        })
        .finally(end => {
          this.loading.delete = false
        })
    },
    showReviewPickup(pickup) {
      if (pickup.need_evaluation) {
        this.currentPickup.evaluate = pickup
        this.$bvModal.show('modalEvaluateReturnPickup')
      } else {
        const text = `No es posible evaluar el retiro ya que aún no se llega a la fecha y hora de evaluación - <b>${pickup.format_pickup_date} a las 20:00 Hrs</b>.`
        this.$info(text, null, '¡Evaluación pendiente!')
      }
    },
    updatePickupStatus () {
      this.getReturnPickups()
    },
    updateAdvancedFilters (data) {
      this.advancedFilters = data
      this.$bvModal.hide('modalReturnPickupFilters')
      this.filterList({...this.advancedFilters, ...this.formFilter})
    }
  }
}
</script>

<style>
  .table_dot--state{
    position: relative;
    display: inline-block;
    top: 3px;
    height: 15px;
    width: 15px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  }

  .table_dot--status-scheduled {
    background-color:rgb(77, 152, 213)
  }

  .table_dot--status-done {
    background-color: #55D6A7;
  }

  .table_dot--status-failed {
    background-color: rgb(214, 85, 94)
  }
  .table_dot--status-canceled {
    background-color: rgb(223, 38, 50)
  }
</style>