<template>
  <div>
    <b-modal id="modalReturnPickupDetails" size="lg" :title="$t('Información del retiro')" ok-variant="outline-success"
      ok-only :ok-title="$t('Cerrar')" centered>
        <div v-if="detailPickup && detailPickup.identifier">
          <div class="row">
            <!-- Identifier -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Número de retiro')}}</b></label>
              <span class="ml-1">{{ detailPickup.identifier || '--' }}</span>
            </div>
            <!-- Estado -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Estado')}}</b></label>
              <span class="ml-1">{{ detailPickup.status.name || '--' }}</span>
            </div>
          </div>

          
          <div class="row">
            <!-- Empresa -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Empresa')}}</b></label>
              <span class="ml-1">{{ detailPickup.company.name || '--' }}</span>
            </div>
            <!-- Carrier -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Courier')}}</b></label>
              <span class="ml-1">{{ pickup.carrier.name || '--' }}</span>
            </div>
          </div>

          <div class="row">
            <!-- Place_level3 -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Place_level3')}}</b></label>
              <span class="ml-1">{{ detailPickup.address.place || '--' }}</span>
            </div>
            <!-- Dirección -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Dirección')}}</b></label>
              <span v-if="detailPickup.address.full_address">
                <span class="ml-1">{{ detailPickup.address.full_address || '--' }}</span>
              </span>
            </div>
          </div>
          
          <div class="row">
            <!-- Bultos -->
            <div class="col-sm-12 col-md-4 mb-1 container-info">
              <label><b>{{$t('Cantidad de bultos')}}</b></label>
              <span class="ml-1">{{ detailPickup.qty || '--' }}</span>
            </div>
            <!-- KG -->
            <div class="col-sm-12 col-md-4 mb-1 container-info">
              <label><b>{{$t('Peso total en KG')}}</b></label>
              <span class="ml-1">{{ detailPickup.weight || '--' }}</span>
            </div>
            <!-- Dimensiones -->
            <div class="col-sm-12 col-md-4 mb-1 container-info">
              <label><b>{{$t('Dimensiones')}}</b></label>
              <span class="ml-1">{{ detailPickup.dimensions || '--' }}</span>
            </div>
          </div>

          <!-- Dimensiones -->
          <div class="row">
            <div class="col-md-12">
              <label><b>{{$t('Volumen promedio')}}</b></label>
              <div> 
                <span class="text-danger">*</span> Volumen unitario promedio de los envíos o el volumen del envío más grande.
              </div>
            </div>
            <div class="col-md-12 row-size">
              <div class="flex" style="display: flex; flex-direction: column; align-items: center;">
                <img :src="packageSizeImgs.letter" alt="Size_XS" width="25">                                                                             
                <label class="radio-inline">
                  <b-form-radio v-model="detailPickup.size" :disabled="true"  name="some-radios" value="xs">Sobre</b-form-radio>
                </label>
              </div>
          
              <div class="flex" style="display: flex; flex-direction: column; align-items: center;">
                <img :src="packageSizeImgs.small" alt="Size_S" width="36">                                                                                                                                                                          
                <label class="radio-inline">
                  <b-form-radio v-model="detailPickup.size" :disabled="true"  name="some-radios" value="s">Pequeño</b-form-radio>
                </label>
              </div>
          
              <div class="flex" style="display: flex; flex-direction: column; align-items: center;">
                <img :src="packageSizeImgs.medium" alt="Size_M" width="53">                                                                                      
                <label class="radio-inline">
                  <b-form-radio v-model="detailPickup.size" :disabled="true"  name="some-radios" value="m">Mediano</b-form-radio>
                </label>
              </div>
          
              <div class="flex" style="display: flex; flex-direction: column; align-items: center;">
                <img :src="packageSizeImgs.large" alt="Size_L" width="68">                                                                                      
                <label class="radio-inline">
                  <b-form-radio v-model="detailPickup.size" :disabled="true"  name="some-radios" value="l">Grande</b-form-radio>
                </label>
              </div>
          
              <div class="flex" style="display: flex; flex-direction: column; align-items: center;">
                <img :src="packageSizeImgs.extraLarge" alt="Size_C" width="81" style="opacity: .7;"> 
                <label class="radio-inline">
                  <b-form-radio v-model="detailPickup.size" :disabled="true"  name="some-radios" value="c">Más Grande</b-form-radio>
                </label>
              </div>
            </div>

            <div class="col-md-12 mt-1" v-if="detailPickup.size === 'c'">
              <label><b>{{$t('Descripción de producto')}}</b></label>
              <div class="ml-1">{{ detailPickup.description || '--' }}</div>
            </div>
          </div>
          <hr>

          <div class="row">
            <!-- Fecha de retiro -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Fecha de retiro')}}</b></label>
              <span class="ml-1">{{ detailPickup.format_pickup_date || '--' }}</span>
            </div>
            <!-- Rango horario -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Rango horario')}}</b></label>
              <span class="ml-1">{{ detailPickup.range_time || '--' }}</span>
            </div>
          </div>

          <!-- Sección contacto -->
          <div class="row">
            <!-- Nombre contacto -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Nombre contacto')}}</b></label>
              <span class="ml-1">{{ detailPickup.contact.name || '--' }}</span>
            </div>
            <!-- Teléfono -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Teléfono de contacto')}}</b></label>
              <span class="ml-1">{{ detailPickup.contact.phone || '--' }}</span>
            </div>
            <!-- Correo -->
            <!-- <div class="col-sm-12 col-md-12 mb-1 container-info">
              <label><b>{{$t('Correo contacto bodega')}}</b></label>
              <span class="ml-1">{{ detailPickup.contact.email || '--' }}</span>
            </div> -->
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-12 mb-1 container-info">
              <label><b>{{$t('Observación')}}</b></label>
              <span class="ml-1">{{ detailPickup.information || '--' }}</span>
            </div>
          </div>

          <!-- Evaluación -->
          <div v-if="detailPickup.evaluation && user.role === 'admin'" class="row">
            <div class="col-md-12 mb-1">
              <p><u>{{$t('Detalles de evaluación')}}</u></p>
            </div>
            <!-- Usuario evaluador y origen -->
            <div class="col-sm-12 col-md-4 mb-1 container-info">
              <label><b>{{$t('Nombre del usuario')}}</b></label>
              <span class="ml-1">{{ detailPickup.evaluation.user.full_name || '--' }}</span>
            </div>
            <div class="col-sm-12 col-md-4 mb-1 container-info">
              <label><b>{{$t('Correo del usuario')}}</b></label>
              <span class="ml-1">{{ detailPickup.evaluation.user.email || '--' }}</span>
            </div>
            <div class="col-sm-12 col-md-4 mb-1 container-info">
              <label><b>{{$t('Origen de evaluación')}}</b></label>
              <span class="ml-1">{{ evaluationOriginLabel || '--' }}</span>
            </div>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ['pickup'],
  data () {
    return {
      packageSizeImgs: {
        letter: require('@/assets/images/images-on-components/packages-size/package-size-letter.png'),
        small: require('@/assets/images/images-on-components/packages-size/package-size-small.png'),
        medium: require('@/assets/images/images-on-components/packages-size/package-size-medium.png'),
        large: require('@/assets/images/images-on-components/packages-size/package-size-large.png'),
        extraLarge: require('@/assets/images/images-on-components/packages-size/package-size-extra-large.png')
      },
      user: this.$session.get('cas_user'),
      detailPickup: {
        address: {
          full_address: '',
          place_name: '',
          place: '',
          information: ''
        }
      }
    }
  },
  computed: {
    evaluationOriginLabel() {
      if (this.detailPickup.evaluation && this.detailPickup.evaluation.origin) {
        const origin = this.detailPickup.evaluation.origin
        if (origin === 'platform') {
          return this.$t('Plataforma')
        } else if (origin === 'public') {
          return this.$t('Vista pública')
        }
      }
      return '--'
    }
  },
  watch: {
    'pickup': {
      handler(value) {
        value.format_pickup_date = this.$options.filters.moment(value.pickup_date, 'DD-MM-YYYY')
        if (value.evaluation) {
          const { user } = value.evaluation
          value.evaluation.user.full_name = `${user.first_name} ${user.last_name}`
        }
        this.detailPickup = value
      },
      deep: true
    }
  }
}
</script>

<style>
.row-size {
  display:flex; 
  flex-direction: row; 
  justify-content: space-around;
}
.flex {
  display: flex;
  justify-content: end;
}
.link-show-address {
  text-decoration: underline;
}
</style>