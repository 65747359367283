<template>
  <div>
    <b-modal id="modalReturnPickupFilters" centered modal-class="dialog-1000 custom-dialog"
      @ok="ok" ok-variant="warning" :ok-title="$t('Buscar')" ok-only
      :title="$t('Búsqueda avanzada')">
        <div class="mt-0">
          <form-render :fields="fields" :form.sync="formFilters" @send="onAccept"
            ref="formRenderReturnPickupFilters"
            containerButtonsClass="col-sm-12 col-lg-4 container-button">
          </form-render>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TabReturnPickupsService from './returnPickups.service'
export default {
  props: ['clearFilters'],
  data() {
    return {
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin'
      },
      fields: [],
      formFilters: {},
      tabReturnPickupsService: new TabReturnPickupsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    clearFilters () {
      // Limpiar filtros avanzados
      this.formFilters = {}
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.fields = [
        {
          fieldType: 'FieldSelect', 
          name: 'organization', 
          label: 'Marketplace', 
          containerClass: 'col-sm-12 container-info', 
          searchOnType: { 
            fx: this.searchOrganizations, 
            nChars: 3, 
            debounce: 300, 
            allowSearchAsValue: true, 
            persistSearch: true 
          } 
        },
        {
          fieldType: 'FieldSelect', 
          name: 'shipper', 
          label: 'Empresa', 
          containerClass: 'col-sm-12 container-info', 
          searchOnType: { 
            fx: this.searchShippers, 
            nChars: 3, 
            debounce: 300, 
            allowSearchAsValue: true, 
            persistSearch: true 
          } 
        },
        {
          fieldType: 'FieldSelect', 
          name: 'carrier', 
          label: 'Courier', 
          containerClass: 'col-sm-12 container-info', 
          searchOnType: { 
            fx: this.getCarriers, 
            nChars: 3, 
            debounce: 300, 
            allowSearchAsValue: true, 
            persistSearch: true 
          }
        }
      ]

      if (this.mySession.role !== this.ROLES.admin) {
        this.editFormByRole()
      }
    },
    /** 
     * Función que saca campos del formulario segun roles de usuario
    */
    editFormByRole () {
      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) this.fields = this.fields.filter(field => !['organization', 'shipper', 'country_code'].includes(field.name))
      if (this.mySession.role === this.ROLES.marketplace) this.fields = this.fields.filter(field => !['organization', 'country_code'].includes(field.name))
    },
    /** 
    * Busca el indice de un campo segun su nombre
    */
    selectionIndex(name) {
      return this.fields.findIndex(el => el.name === name)
    },
    searchOrganizations (value) {
      return this.tabReturnPickupsService.callService('getOrganizations', { name: value }, null)
        .then(response => {
          return response.data.map(el => ({ ...el, text: `${el.id} - ${el.name}` }))
        })
        .catch(error => console.error(error))
    },
    searchShippers(value) {
      let service = 'getShippers'
      const params = {}
      if (this.mySession.role === 'marketplace') {
        service = 'getShippersByOrganizationSimplified'
        params.organization_id = this.mySession.organization.id
      }
      return this.tabReturnPickupsService.callService(service, {name: value}, params)
        .then(response => {
          return response.data.map(el => ({...el, text: `${el.id} - ${el.name1 ? el.name1 : el.name}`}))
        })
        .catch(error => console.error(error))
    },
    getCarriers(value) {
      const queryParams = {
        // materials_enabled: true,
        name: value
      }
      return this.tabReturnPickupsService.callService('getCarriers', queryParams)
        .then(response => response.data.map(el => ({ ...el, text: `${el.code} - ${el.name}`, id: el.code })))
        .catch(error => console.error(error))
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderReturnPickupFilters.checkForm()
    },
    onAccept () {
      this.$emit('updateAdvancedFilters', this.formFilters)
    }
  }
}
</script>

<style>

</style>