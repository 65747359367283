<template>
  <div>
    <b-modal id="modalCreateReturnPickup"
    :title="$t('Crear retiro')"
    no-close-on-esc
    no-close-on-backdrop
    centered
    size="lg"
    @show="close"
    hide-footer>
      <div class="mt-0">
        <form-render ref="formRenderCreateReturnPickup" 
          :fields="fields"
          :key="keyFormRender"
          :form.sync="pickupRequest"
          @send="createPickup">
            <template #close_time v-if="display.closeTimeField">
              <div>
                <label for="timepicker-courthours">Hora cierre bodega</label>
                <b-form-timepicker
                  class="col-md-6"
                  id="datepicker-close-time"
                  locale="en"
                  minutes-step="5"
                  placeholder="Selecciona una hora"
                  hide-header
                  label-close-button="Cerrar"
                  label-no-time-selected="No se ha seleccionado una hora"
                  v-model="pickupRequest.close_time"
                ></b-form-timepicker>
              </div>
            </template>
        </form-render>
      </div>
      <template>
        <b-button @click="ok" class="push-right" variant="warning" :disabled="loading.createPickup">
          {{$t('Crear retiro')}}
          <feather-icon v-if="!loading.createPickup" icon="DownloadIcon"/>
          <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/> 
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import TabReturnPickupsService from './returnPickups.service'
export default {
  data () {
    return {
      fields: [],
      pickupRequest: {},
      keyFormRender: 0,
      // carrierConfig: {},
      loading: {
        createPickup: false
      },
      display: {
        closeTimeField: false
      },
      dynamicFields: [],
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin'
      },
      sizePickup: [
        { id: 'xs', text: 'Sobre' },
        { id: 's', text: 'Pequeño' },
        { id: 'm', text: 'Mediano' },
        { id: 'l', text: 'Grande' },
        { id: 'c', text: 'Más Grande' }
      ],
      currentDate: new Date(),
      maxDate: new Date(),
      daysInCalendar: 60,
      countriesData: {
        CL: {
          phone_code:56,
          phone_digits:9,
          levels: 3,
          labelText: 'Comuna'
        },
        MX: {
          phone_code:52,
          phone_digits:10,
          levels: 4,
          labelText: 'Colonia'
        },
        CO: {
          phone_code:57,
          phone_digits:13, 
          levels: 2,
          labelText: 'Departamento'
        },
        PE: {
          phone_code:51,
          phone_digits:9,
          levels: 3,
          labelText: 'Distrito'
        }
      },
      fieldSkipLine: { name: '', useSkeleton: true, skipLine: true, containerClass: 'col-12' },
      dimensionLabel: { name: 'dimensionLine', label: 'Medidas (Alto, Ancho, Largo en cm)', useSkeleton: true, skipLine: true, containerClass: 'col-12' },
      tabReturnPickupsService: new TabReturnPickupsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.fields = [
        {
          fieldType: 'FieldSelect',
          name: 'shipper',
          label: 'Empresa',
          validation: 'required',
          containerClass: 'col-md-6 container-info',
          change: this.onChangeCompany,
          clearable: false,
          searchOnType: {
            fx: this.getShippers,
            nChars: 2,
            debounce: 300,
            allowSearchAsValue: true,
            persistSearch: true
          } 
        },
        {
          fieldType: 'FieldSelect',
          name: 'carrier',
          label: 'Courier',
          validation: 'required',
          containerClass: 'col-md-6 container-info',
          change: this.onChangeCarrier,
          clearable: false
        },
        {
          fieldType: 'FieldInput',
          name: 'full_address',
          label: 'Lugar de retiro',
          validation: 'required',
          containerClass: 'col-md-6 container-info'
        },
        {
          fieldType: 'FieldInput',
          name: 'qty',
          label: 'Cantidad de bultos',
          validation: 'required',
          containerClass: 'col-md-3 container-info'
        },
        {
          fieldType: 'FieldInput',
          name: 'weight',
          type: 'number',
          label: 'Peso total en kg',
          validation: 'required',
          containerClass: 'col-md-3 container-info'
        },
        {
          fieldType: 'FieldSelect',
          name: 'place',
          dependency: 'shipper',
          label: 'Place_level3',
          validation: 'required',
          containerClass: 'col-md-6 container-info',
          clearable: false,
          searchOnType: {
            fx: this.getPlaces,
            nChars: 3,
            debounce: 300,
            allowSearchAsValue: true,
            persistSearch: true
          } 
        },
        {
          fieldType: 'FieldInput',
          name: 'address_information',
          label: 'Información adicional',
          validation: 'required',
          containerClass: 'col-md-6 container-info'
        },
        this.fieldSkipLine,
        {
          fieldType: 'FieldRadio',
          name: 'size',
          label: 'Volumen promedio',
          validation: 'required',
          containerClass: 'col-md-12 my-1 border-bottom pb-2',
          change: this.onChangeSize,
          align: 'h',
          optionalSecondLabel: 'Ingresar el volumen unitario promedio de los envíos o el volumen del envío más grande.',
          options: this.sizePickup
        },
        { name: 'deliveries', useSlot: true, containerClass: 'col-md-12 container-info' },
        {
          fieldType: 'FieldDatepicker',
          label: 'Fecha de retiro',
          name: 'pickup_date',
          propsVCalendar: { 
            'min-date': this.currentDate,
            'max-date': this.maxDate.setDate(this.currentDate.getDate() + this.daysInCalendar)
          },
          disabledDates: { weekdays: [1, 7] },
          validation: 'requiredDate',
          containerClass: 'col-md-6 container-info'
          // change: this.onChangePickupDate
        },
        {
          fieldType: 'FieldSelect',
          name: 'range_time',
          dependency: 'pickup_date',
          label: 'Rango horario',
          validation: 'required',
          containerClass: 'col-md-6 container-info',
          keyFormRender: 0
        },
        {
          fieldType: 'FieldInput',
          name: 'contact_name',
          label: 'Nombre contacto',
          validation: 'required',
          containerClass: 'col-md-6 container-info'
        },
        {
          fieldType: 'FieldInput',
          name: 'contact_phone',
          dependency: 'shipper',
          type: 'number',
          label: 'Teléfono de contacto',
          containerClass: 'col-md-6 container-info',
          validation: 'required',
          prepend: { text: '+', color: '#BBBBBB' }
        },
        { name: 'close_time', useSlot: true, containerClass: 'col-md-12 container-info' },
        { 
          fieldType: 'FieldTextarea',
          name: 'information',
          label: 'Observación',
          containerClass: 'col-md-12 container-info'
        }
      ]
      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) {
        // Se saca el campo de shipper del formulario de creacion y se setea el campo con el id del usuario
        this.fields = this.fields.filter(field => !['shipper'].includes(field.name))
        this.pickupRequest.shipper = this.mySession.shipper
        this.onChangeCompany()
      }
      this.pickupRequest.size = { id: 'xs', text: 'Sobre' }
    },
    // FUNCIONES GET
    getShippers(value) {
      const payload = {
        search: value,
        extra_fields: 'country'
      }
      if (this.mySession.role === 'marketplace') payload.mkp = this.mySession.organization.id
      
      return this.tabReturnPickupsService.callService('getShippers', payload)
        .then(resp => {
          return resp.data.map(el => ({...el, text: `${el.id} - ${el.name1 ? el.name1 : el.name}`}))
        })
        .catch(error => console.error(error))
    },
    getCarriers(shipper_id) {
      const queryParams = {
        extra_fields: 'customization,return_configuration'
      }
      return this.tabReturnPickupsService.callService('getCarriersDistribution', queryParams, { shipper_id })
        .then(resp => {
          // Se rellena el selector "Carrier" con el listado de carriers
          const carriers = resp.data.map(el => ({ ...el, text: el.name }))
          this.setSelectOptions('carrier', carriers)
        })
        .catch(error => console.error(error))
    },
    getPlaces (search) {
      const { country } = this.pickupRequest.shipper
      const queryParams = {
        name: search
      }
      const params = {
        country_code: country.code.toLowerCase()
      }
      return this.tabReturnPickupsService.callService('getPlaces', queryParams, params)
        .then(resp => {
          return resp.data.map(el => ({ ...el, text: el.full_name }))
        })
    },
    getOts (ot) {
      // tracking_number=231203&company_id=1256
      const queryParams = {
        tracking_number: ot,
        company_id: this.pickupRequest.shipper.id
      }
      return this.tabReturnPickupsService.callService('getOts', queryParams)
        .then(resp => {
          return resp.data.map(el => ({ ...el, text: el.tracking_number }))
        })
    },
    getNonWorkingDays (carrier_code) {
      const queryParams = {
        company_id: this.pickupRequest.shipper.id,
        days: this.daysInCalendar
      }
      return this.tabReturnPickupsService.callService('getNonWorkingDays', queryParams, { carrier_code })
        .then(resp => {
          // Actualizamos los dias invalidos del calendario "pickup_date"
          this.fields[this.selectionIndex('pickup_date')].disabledDates = resp.data.map(date => moment(date).toDate())
        })
        .catch(error => console.error(error))
    },
    // FUNCIONS SET
    setRangeTimes() {
      // Se rellena el selector "Rango horario" con los rangos horarios del carrier seleccionado
      this.carrierConfig = {
        ...this.pickupRequest.carrier.return_configuration,
        time_zone: this.pickupRequest.carrier.time_zone
      }
      const rangeTime = this.pickupRequest.carrier.return_configuration.range_time.map((el, index) => ({ 
        ...el,
        id: index,
        text: el.label,
        disabled: this.isRangeDisabled(el)
      }))

      this.setSelectOptions('range_time', rangeTime)
    },
    setDimensionFields(size) {
      if (size.id === 'c') {
        // Si es "C" se despliegan los nuevos campos (alto, ancho, largo y descripción).
        const customSizeFields = [
          this.dimensionLabel,
          {
            fieldType: 'FieldInput',
            name: 'height',
            type: 'number',
            label: 'Alto',
            validation: 'required',
            containerClass: 'col-md-2 container-info my-1 border-bottom pb-2'
          },
          {
            fieldType: 'FieldInput',
            name: 'width',
            type: 'number',
            label: 'Ancho',
            validation: 'required',
            containerClass: 'col-md-2 container-info my-1 border-bottom pb-2'
          },
          {
            fieldType: 'FieldInput',
            name: 'length',
            type: 'number',
            label: 'Largo',
            validation: 'required',
            containerClass: 'col-md-2 container-info my-1 border-bottom pb-2'
          },
          {
            fieldType: 'FieldTextarea',
            name: 'description',
            label: 'Descripción de los productos',
            validation: 'required',
            containerClass: 'col-md-6 container-info my-1 border-bottom pb-2'
          }
        ]
        this.fields.splice(this.selectionIndex('size') + 1, 0, ...customSizeFields)
      } else {
        // Si no es "C" no se muestra nada y se limpian los campos
        this.fields = this.fields.filter(field => !['height', 'width', 'length', 'description', 'dimensionLine'].includes(field.name))
        delete this.pickupRequest.height
        delete this.pickupRequest.width
        delete this.pickupRequest.length
        delete this.pickupRequest.description
      }

    },
    setHour(carrier) {
      const carrierCurrentHour = this.applyTimeZone(carrier.time_zone)
      const configuration = carrier.return_configuration

      if (configuration.time_limit) {
        const eleventhHour = moment(configuration.time_limit, 'h:mm')
        const pickupDate = moment()
        const daysBefore = configuration.days_before ?? 0

        // Verifica el horario de corte
        if (carrierCurrentHour.isSameOrAfter(eleventhHour)) {
          pickupDate.add(1, 'day')
        }
        // Se setea la fecha mínima del retiro como la fecha actual más los días hábiles de anticipación
        const minPickupDate = this.getDateWithBusinessDaysBefore(pickupDate, daysBefore)
        this.fields[this.selectionIndex('pickup_date')].propsVCalendar['min-date'] = minPickupDate.toDate()
        // Actualizamos la fecha de retiro y la fecha de inicio del calendario, dependiendo de la fecha mínima del retiro
        this.pickupRequest.pickup_date = pickupDate.isBefore(minPickupDate) ? minPickupDate : pickupDate
      }
    },

    /**
     * Retorna la fecha más los días hábiles de anticipación
     * @param {moment} startDate Fecha de inicio
     * @param {number} daysBefore Días hábiles de anticipación
     * @returns {moment} Objeto Moment que representa la fecha resultante
     */
    getDateWithBusinessDaysBefore(startDate, daysBefore) {
      const pickupDateIndex = this.selectionIndex('pickup_date')
      const disabledDates = this.fields[pickupDateIndex].disabledDates
      const today = startDate.clone()
      let businessDays = 0
      while (businessDays < daysBefore) {
        today.add(1, 'days')
        const isDisabled = this.isDateDisabled(today, disabledDates)
        if (!isDisabled) {
          businessDays++
        }
      }
      return today
    },
    isDateDisabled (date, disabledDates) {
      if (Array.isArray(disabledDates)) {
        return disabledDates.some(day => date.day() === (day === 1 ? 7 : day - 1))
      } else if (typeof disabledDates === 'object' && disabledDates.weekdays) {
        return disabledDates.weekdays.includes(date.day() === 1 ? 7 : date.day() - 1)
      } else {
        return false
      }
    },
    isRangeDisabled(range) {
      const { time_zone, time_limit } = this.carrierConfig
      const pickupDate = moment(this.pickupRequest.pickup_date)
      const todayWithTimeZone = this.applyTimeZone(time_zone)
      const sameDay = todayWithTimeZone.isSame(pickupDate, 'day')

      // Si no es el mismo dia (hoy) retorna false al instante ya que no estamos agendando para hoy mismo
      if (!sameDay) return false
      if (!time_limit) return false

      const todayHour = todayWithTimeZone.format('HH:mm')
      if (time_limit < todayHour) return true

      const timeToStr = range.to
      const timeToMinusTwoHours = moment(timeToStr, 'HH:mm').subtract(2, 'hours').format('HH:mm')
      if (timeToMinusTwoHours < todayHour) return true

      return false
    },
    applyTimeZone(timeZone) {
      const date = new Date()
      return moment(date.toLocaleString('en-US', { timeZone }))
    },
    setOTField() {
      if (this.pickupRequest.carrier.code === 'BLX') {
        // Si es "BLX se agreag el campo OT
        const OTField = {
          fieldType: 'FieldSelect',
          name: 'tracking_number',
          label: 'OT',
          multiple: true,
          containerClass: 'col-md-6 container-info',
          clearable: false,
          searchOnType: {
            fx: this.getOts,
            nChars: 3,
            debounce: 300,
            persistSearch: true,
            allowSearchAsValue: false
          } 
        }
        
        this.fields.splice(this.selectionIndex('address_information') + 1, 0, OTField, this.fieldSkipLine)
      } else {
        // Si no es "BLX Se elimina el campo OT
        this.fields = this.fields.filter(field => !['tracking_number'].includes(field.name))
        delete this.pickupRequest.tracking_number
      }
    },
    setDynamicFieldsByCarrier () {
      this.clearDynamicFields()
      this.fields = this.fields.filter(field => !field.isDynamicFields)
      const additional_info = this.pickupRequest.carrier.return_configuration.additional_info
      if (additional_info) {
        const dynamicFields = additional_info.map(el => (({
          fieldType: 'FieldInput',
          isDynamicFields: true,
          name: `additional_data_${el.propertyObject}`,
          label: el.inputLabel,
          textHelp: `Máximo ${el.htmlProperties.maxlength} caracteres *`,
          max: el.htmlProperties.maxlength,
          // validation: el.htmlProperties.required,
          validation: 'required',
          containerClass: 'col-sm-12 col-md-6 container-info',
          placeholder: `Ingrese ${el.inputLabel}`
        })))

        this.fields.splice(this.selectionIndex('size') - 1, 0, ...dynamicFields)
        this.dynamicFields = dynamicFields
      }
    },
    setCloseTimePicker() {
      delete this.pickupRequest.close_time
      if (this.pickupRequest.carrier.customization.pick_up_options.close_time) {
        this.display.closeTimeField = true
      } else {
        this.display.closeTimeField = false
      }
    },
    clearDynamicFields () {
      this.fields = this.fields.filter(field => !field.isDynamicFields)
      this.dynamicFields.map(dynamicField => {
        delete this.pickupRequest[dynamicField.name]
      })
    },
    // FUNCIONES ONCHANGE
    onChangeCompany () {
      // Limpiamos los campos correspondientes al cambio de empresa
      delete this.pickupRequest.carrier
      delete this.pickupRequest.range_time
      // delete this.pickupRequest.pickup_date
      // Se limpia el contact_phone para prevenir errores de codigo de pais
      delete this.pickupRequest.contact_phone
      this.pickupRequest.qty = null

      if (!this.pickupRequest.shipper) {
        this.setSelectOptions('carrier', [])
        this.fields[this.selectionIndex('contact_phone')].prepend = { text: '+', color: '#BBBBBB' }
        delete this.fields[this.selectionIndex('contact_phone')].max
      } else {

        const countryCode = this.pickupRequest.shipper.country.code
        if (this.countriesData[countryCode]) {
          const phoneCode = this.countriesData[countryCode].phone_code
          const phoneDigits = this.countriesData[countryCode].phone_digits
          this.fields[this.selectionIndex('contact_phone')].prepend = { text: `+${phoneCode}` }
          this.fields[this.selectionIndex('contact_phone')].max = phoneDigits
        }
        // Funciones para rellenar selectores
        this.getCarriers(this.pickupRequest.shipper.id)
      }
    },
    onChangeCarrier() {
      // Se borra la seleccion de rango horario
      delete this.pickupRequest.range_time
      this.setRangeTimes()
      this.setHour(this.pickupRequest.carrier)
      // Se inserta el campo de OT y campos dinamicos de carriers
      this.setOTField()
      this.setDynamicFieldsByCarrier()
      this.setCloseTimePicker()
      // Llamado a los dias no laborales
      this.getNonWorkingDays(this.pickupRequest.carrier.code)
    },
    onChangeSize() {
      this.setDimensionFields(this.pickupRequest.size)
    },
    close() {
      this.pickupRequest = {}

      this.fields = this.fields.filter(field => !['tracking_number'].includes(field.name))
      this.clearDynamicFields()
      this.display.closeTimeField = false

      this.fields[this.selectionIndex('contact_phone')].prepend = { text: '+', color: '#BBBBBB' }
      delete this.fields[this.selectionIndex('contact_phone')].max
      // Reseteo del calendario
      this.fields[this.selectionIndex('pickup_date')].propsVCalendar = { 
        'min-date': this.currentDate,
        'max-date': this.maxDate.setDate(this.currentDate.getDate() + this.daysInCalendar)
      }
      this.fields[this.selectionIndex('pickup_date')].disabledDates = { weekdays: [1, 7] }
      this.pickupRequest.size = { id: 'xs', text: 'Sobre' }
      this.fields = this.fields.filter(field => !['height', 'width', 'length', 'description', 'dimensionLine'].includes(field.name))

      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) this.setInitialData()
    },
    /**
     * Actualiza las opciones de un campo del tipo FieldSelect
     * @param {string} name Nombre del campo a actualizar sus opciones
     * @param {any[]} options Opciones a actualizar
     */
    setSelectOptions(name, options) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].options = options
      if (this.fields[index].keyFormRender !== undefined) this.fields[index].keyFormRender++
      else this.keyFormRender++
    },
    /** 
    * Busca el indice de un campo segun su nombre
    */
    selectionIndex(name) {
      return this.fields.findIndex(el => el.name === name)
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderCreateReturnPickup.checkForm()
    },
    createPickup () {
      this.loading.createPickup = true
      const { first_name, last_name, email, role } = this.mySession
      const {
        shipper,
        carrier,
        qty,
        weight,
        full_address,
        place,
        address_information,
        size,
        pickup_date,
        range_time,
        contact_name,
        contact_phone
      } = this.pickupRequest

      // Se construye el payload base
      const payload = {
        source: 'platform',
        company: shipper,
        carrier_code: carrier.code,
        qty,
        weight,
        address: {
          full_address,
          place: place.name,
          place_id: place.id,
          information: address_information
        },
        pick_up_date: moment(pickup_date).format('YYYY-MM-DD'),
        contact_name,
        contact_phone,
        range_time: range_time.label,
        size: size.id,
        force: 'false',
        user: {
          first_name,
          last_name,
          email,
          role
          // pudo_id
        }
      }
      // Si tiene campos dinamicos
      if (this.pickupRequest.additional_data_address && this.pickupRequest.additional_data_description) {
        payload.additional_data = {
          address: this.pickupRequest.additional_data_address,
          description: this.pickupRequest.additional_data_description
        }
      }

      // Si existe campo de OT
      if (this.pickupRequest.tracking_number) {
        payload.return_deliveries = this.pickupRequest.tracking_number.map(otObj => otObj.tracking_number)
      }

      // Si el size es "c" se agrega custom size
      if (this.pickupRequest.size.id === 'c') {
        payload.height = this.pickupRequest.height
        payload.width = this.pickupRequest.width
        payload.length = this.pickupRequest.length
        payload.description = this.pickupRequest.description
      }

      // Si existe el campo "horio cierre bodega"
      if (this.pickupRequest.close_time) {
        payload.closing_time = moment(this.pickupRequest.close_time, 'HH:mm:ss').format('H:mm')
      }

      // Si existe el campo "Observaciones"
      if (this.pickupRequest.close_time) {
        payload.information = this.pickupRequest.information
      }

      this.tabReturnPickupsService.callService('createReturnPickup', payload, { shipper_id: shipper.id }, { fullResponseError: true })
        .then(resp => {
          this.$bvToast.toast('Felicidades!', {
            title: 'Creación exitosa!',
            variant: 'success',
            autoHideDelay: 3500
          })
          this.$emit('updateList', resp.data)
        })
        .catch(error => {
          console.error(error)
          this.$alert(error)
        })
        .finally(() => this.loading.createPickup = false)
    }
  }
}
</script>

<style>

</style>